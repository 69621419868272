<template>
  <div class="contractTableBox">
    <div class="cardTabBox">
      <el-tabs type="border-card" v-model="cardActive" @tab-click="handleClick">
        <el-tab-pane
          :label="$t('market.foreignExchange') + '/' + $t('market.nobleMetal')"
          name="1"
        ></el-tab-pane>
        <el-tab-pane
          :label="$t('market.digitalCurrency')"
          name="2"
        ></el-tab-pane>
      </el-tabs>
    </div>
    <div class="contractHeader">
      <div class="contractLeft flex">
        <div :class="{ active: active == '0' }" @click="changeAcitve('0')">
          {{ $t("market.fixedPrice") }}
        </div>
        <div :class="{ active: active == '1' }" @click="changeAcitve('1')">
          {{ $t("market.commission") }}
        </div>
      </div>
      <i
        @click="router.push('/assetsRecord')"
        class="el-icon-time"
        style="
          color: #5670f6;
          font-size: 18px;
          font-weight: bold;
          cursor: pointer;
        "
      ></i>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      :empty-text="$t('rankText.emptyText')"
    >
      <el-table-column prop="date" align="center" :label="$t('rankText.name')">
        <template slot-scope="scope">
          <span>{{ scope.row.symbol }}</span>
          <el-tag type="warning" size="mini" style="margin-left: 5px"
            >{{ scope.row.multiple }}X</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="number"
        align="center"
        :label="$t('market.openingNum')"
      >
      </el-table-column
      ><el-table-column
        prop="nowPrice"
        align="center"
        :label="$t('market.transactionPrice')"
      >
        <template slot-scope="scope">
          <span>{{ toSelfFixed(scope.row.price, 2) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="takeProfitPrice"
        align="center"
        :label="$t('market.newPrice')"
      >
        <template slot-scope="scope">
          <span>{{ toSelfFixed(scope.row.update_price, 2) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="stopLossPrice"
        align="center"
        :label="$t('market.guaranteePrice')"
      >
        <template slot-scope="scope">
          <span>{{ toSelfFixed(scope.row.caution_money, 2) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="stopLossPrice"
        align="center"
        :label="$t('market.serviceCharge')"
      >
        <template slot-scope="scope">
          <span>{{ toSelfFixed(scope.row.trade_fee, 2) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="profits"
        align="center"
        :label="$t('market.profitLoss')"
      >
        <template slot-scope="scope">
          <span>{{ toSelfFixed(scope.row.profits, 2) }}</span>
        </template>
      </el-table-column>

      <el-table-column align="center" :label="$t('market.typeName')">
        <template slot-scope="scope">
          <el-tag
            :type="scope.row.type == 1 ? 'success' : 'danger'"
            size="mini"
            style="margin-left: 5px"
            >{{
              scope.row.type == 1 ? $t("market.doMore") : $t("market.doLess")
            }}</el-tag
          >
          <el-tag size="mini" style="margin-left: 5px">{{
            scope.row.trans_type == 1
              ? $t("market.commissionText")
              : $t("market.fixedText")
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="time" align="center" :label="$t('market.time')">
      </el-table-column>
      <el-table-column
        prop="time"
        align="center"
        :label="$t('rankText.operation')"
      >
        <template slot-scope="scope">
          <el-button
            type="primary"
            @click="closeBank(scope.row)"
            size="mini"
            v-if="scope.row.status == 1"
            >{{ $t("order.closingText") }}</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script setup>
import { computed, ref, getCurrentInstance } from "vue";
import { Message, MessageBox } from "element-ui";
import { useRouter } from "@/utils/vueApi";
import { toSelfFixed } from "@/utils/common";
import { numberClose, whclose } from "@/api/market";
import { useLang } from "@/hooks/useLang";
const { proxy } = getCurrentInstance();
const { lang } = useLang();
const router = useRouter();
const props = defineProps({
  tableData: {
    type: Array,
    default: () => [],
  },
});
const emits = defineEmits("getTableData");
const cardActive = ref("1");
// 类型切换
const handleClick = () => {
  emits("getTableData", cardActive.value, active.value);
};
const active = ref("0");
const changeAcitve = (e) => {
  active.value = e;
  emits("getTableData", cardActive.value, active.value);
};
// 平仓
const closeBank = async (row) => {
  MessageBox.confirm(proxy.$t("tips.sureClosing"), proxy.$t("tips.tip"), {
    showClose: true,
    confirmButtonText: proxy.$t("tips.sureText"),
    cancelButtonText: proxy.$t("tips.cancelText"),
    type: "warning",
  }).then(async () => {
    // 贵金属
    if (cardActive.value == "1") {
      const { type, message } = await whclose({ id: row.id, lang: lang.value });
      if (type == "ok") {
        Message.success(proxy.$t(message));
      } else {
        Message.error(proxy.$t(message));
      }
    } else {
      // 数字
      const { type, message } = await numberClose({
        id: row.id,
        lang: lang.value,
      });
      if (type == "ok") {
        Message.success(proxy.$t(message));
      } else {
        Message.error(proxy.$t(message));
      }
    }
    emits("getTableData", cardActive.value, active.value);
  });
};
</script>

<style lang="scss" scoped>
.contractTableBox {
  .cardTabBox {
    :deep(.el-tabs__content) {
      padding: 0;
    }
  }
  .contractHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .contractLeft {
      div {
        cursor: pointer;
        padding: 15px 20px;
        font-size: 14px;
        &.active {
          color: #5670f6;
          font-weight: bold;
          border-bottom: 2px solid #5670f6;
        }
      }
    }
  }
}
</style>
